import { debug } from '../../utils/common';

declare var window: any;
export interface Document {
	documentMode?: any;
}
let InstallTrigger: any;
export interface Window {
	opr: any;
	opera: any;
	safari: any;
	chrome: any;
}

const checkBrowser = () => {
  debug('browser detection script')
	let opr: any, safari: any, output: string;

	// Opera 8.0+
	var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;

	// Firefox 1.0+
	var isFirefox = typeof InstallTrigger !== 'undefined';

	// Safari 3.0+ "[object HTMLElementConstructor]"
	var isSafari =
		/constructor/i.test(window.HTMLElement as any) ||
		(function (p) {
			return p.toString() === '[object SafariRemoteNotification]';
		})(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification));

	// Internet Explorer 6-11
	var isIE = /*@cc_on!@*/ false || !!window.document.documentMode;

	// Edge 20+
	var isEdge = !isIE && !!window.StyleMedia;

	// Chrome 1 - 71
	var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

	// Blink engine detection
	var isBlink = (isChrome || isOpera) && !!window.CSS;

	output = isIE
		? `<div class='browser-warning-container' style='display:none'>
  <div class='warning-container-inner'>
<div class='warning-main'>
<div class='warning-main-inner'>
    <div class='note'>You are using an unsupported browser (Internet Explorer).  Please update to a newer version or use a modern browser .</div>

    <div class='browser-header-info'>See the below clickable icons for info on modern browsers</div>
    <div class='browsers'>
        <div class='browser'>
            <a class='browser-link' href='https://www.microsoft.com/en-us/edge'>
                <div class='browser-img'><img src='/PublishingImages/icons/browser/edge.png' /></div>
                <div class='browser-name'>Microsoft Edge</div>
            </a>
        </div>

        <div class='browser'>
            <a class='browser-link' href='https://www.google.com/chrome' >
                <div class='browser-img'><img src='/PublishingImages/icons/browser/chrome.png' /></div>
                <div class='browser-name'>Google Chrome</div>
            </a>
        </div>

        <div class='browser'>
            <a class='browser-link' href='https://www.mozilla.org/en-US/firefox/new/'>
                <div class='browser-img'><img src='/PublishingImages/icons/browser/firefox.png' /></div>
                <div class='browser-name'>Mozilla Firefox</div>
            </a>
        </div>

        <div class='browser'>
            <a class='browser-link' href='https://www.opera.com/' >
                <div class='browser-img'><img src='/PublishingImages/icons/browser/opera.png' /></div>
                <div class='browser-name'>Opera</div>
            </a>
        </div>
</div>
    </div>
</div>
</div>
</div>`
		: `<span></span>`;

	var observer = new MutationObserver(function () {
		if (document.body) {
			document.body.insertAdjacentHTML('beforeend', output);
			observer.disconnect();
		}
	});
	observer.observe(document.documentElement, { childList: true });
};

checkBrowser();

/*
(function () {
  "use strict";

  var observer = new MutationObserver(function () {
    if (document.body) {
      // It exists now
      document.body.insertAdjacentHTML(
        "beforeend",
        "<div class='browser-result' style='display:none;'>" + checkBrowser() + "</div>'"
      );
      observer.disconnect();
    }
  });
  observer.observe(document.documentElement, { childList: true });
})();
*/

/*
var elem = document.createElement('div');
// elem.style.cssText = 'position:absolute;width:100%;height:100%;opacity:0.3;z-index:100;background:#000';
elem.innerHTML = output;
elem.classList.add('browser-result')
document.appendChild(elem);
*/

// document.body.innerHTML = output;

/*
  var output = 'Detecting browsers by ducktyping:<hr>';
  output += 'isFirefox: ' + isFirefox + '<br>';
  output += 'isChrome: ' + isChrome + '<br>';
  output += 'isSafari: ' + isSafari + '<br>';
  output += 'isOpera: ' + isOpera + '<br>';
  output += 'isIE: ' + isIE + '<br>';
  output += 'isEdge: ' + isEdge + '<br>';
  output += 'isBlink: ' + isBlink + '<br>';
  */
// return output;

/*
  if (isIE) {
      output = `<div><div>`
  }
  else {
      output
  }
*/

/*
    output = isIE ?
      `<div class='warning-container'>
      <div class='warning-main'>
          <div class='note'>You are using an unsupported browser (Internet Explorer).  Please update to a newer version or use a modern browser .</div>

          <div class='browser-header-info'>See the following (clickable icons) for more information on the following modern browsers</div>
          <div class='browsers'>
              <div class='browser'>
                  <a class='browser-link' href='https://www.microsoft.com/en-us/edge'>
                      <div class='browser-img'><img src='/PublishingImages/icons/browser/edge.png' /></div>
                      <div class='browser-name'>Microsoft Edge</div>
                  </a>
              </div>

              <div class='browser'>
                  <a class='browser-link' href='https://www.google.com/chrome' >
                      <div class='browser-img'><img src='/PublishingImages/icons/browser/chrome.png' /></div>
                      <div class='browser-name'>Google Chrome</div>
                  </a>
              </div>

              <div class='browser'>
                  <a class='browser-link' href='https://www.mozilla.org/en-US/firefox/new/'>
                      <div class='browser-img'><img src='/PublishingImages/icons/browser/firefox.png' /></div>
                      <div class='browser-name'>Mozilla Firefox</div>
                  </a>
              </div>

              <div class='browser'>
                  <a class='browser-link' href='https://www.opera.com/' >
                      <div class='browser-img'><img src='/PublishingImages/icons/browser/opera.png' /></div>
                      <div class='browser-name'>Opera</div>
                  </a>
              </div>

          </div>
      </div>
  </div>` :
      `<div class='browser-result' style='display:none'>browser is NOT IE</div>`
  */
